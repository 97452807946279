import { ArrowLeft, ArrowRight } from 'assets/icons'
import { FooterMobile, HeaderMobile } from 'components'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import newsData from 'data/news.json'

const placeholderSquare = 'https://www.wolflair.com/wp-content/uploads/2017/01/placeholder.jpg'

const NewsDetailMobile = () => {
  const { slug } = useParams()
  const navigate = useNavigate()

  const newsDataObj = newsData.find(data => data.slug === slug)

  // OTHER NEWS
  const renderOtherNews = () => {
    return newsData.filter(data => data.type === 'other').map((item, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img loading='lazy' 
          alt={`news-${item.image}`}
          src={item.image}
          style={{ width: '100%', borderRadius: '1.25rem 1.25rem 0 0' }}
        />
        <div style={{
          backgroundColor: '#F2F6F9',
          padding: '2rem',
          borderRadius: '0 0 1rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.75rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#414141' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#414141' }}>
            {item.description}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  return(
    <div>
      <HeaderMobile />

      {/* DETAIL */}
      <div style={{ padding: '3rem 2rem' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1.5rem' }}>
          <div
            onClick={() => navigate('/news')}
            style={{
              cursor: 'pointer',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              color: '#0B4571',
              display: 'flex',
            }}
          >
            <ArrowLeft fill="#fff" /> Back to News
          </div>
        </div>
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', marginBottom: '2rem' }}>
          {newsDataObj.title}
        </div>
        <img loading='lazy'  alt='news-img' src={newsDataObj.image} style={{ width: '100%', borderRadius: '1rem', marginBottom: '2rem' }} />
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.875rem', color: '#6A8192', marginBottom: '1.5rem' }}>
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: newsDataObj.content,
            }}
          />
        </div>
        {newsDataObj.source !== "" && (
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.875rem', fontStyle: 'italic', marginBottom: '2rem' }}>
            Sumber artikel: <br />
            <a href={newsDataObj.source} target='_blank'>
              {newsDataObj.source}
            </a>
          </div>
        )}
      </div>

      {/* OTHER NEWS */}
      <div style={{ padding: '0rem 2rem 3rem 2rem' }}>
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', marginBottom: '1.75rem' }}>
          Berita Lainnya
        </div>
        <div style={{ width: '5.5rem', height: '0.625rem', backgroundColor: '#F04C3E', marginBottom: '2.5rem' }} />
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
          {renderOtherNews()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <button
            onClick={() => navigate('/news')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}
          >
            <ArrowLeft fill="#fff" /> Back to News
          </button>
        </div>
      </div>

      <FooterMobile />
    </div>
  )
}

export default NewsDetailMobile

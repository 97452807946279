import React from "react";

function Thunder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="75"
      fill="none"
      viewBox="0 0 47 75"
    >
      <path
        fill="#40C0C0"
        d="M11.814 1.683A2.343 2.343 0 0114.064 0h18.75a2.344 2.344 0 012.222 3.084l-8.344 25.042h17.84a2.344 2.344 0 011.852 3.783L13.572 74.098a2.344 2.344 0 01-4.093-2.129l8.443-27.436H2.345a2.342 2.342 0 01-2.25-3.005L11.814 1.683zm4.003 3.005L5.477 39.845h15.618a2.343 2.343 0 012.24 3.033L17.72 61.127l22.022-28.314H23.439a2.344 2.344 0 01-2.222-3.084l8.344-25.041H15.817z"
      ></path>
    </svg>
  );
}

export default Thunder;
import './google-play-button-styles.css'

const GooglePlayButton = () => {
  return (
    <div>
      <a
        href="https://play.google.com/store/apps/details?id=id.electrum.elma&pcampaignid=web_share"
        target="_blank"
        className="btn btn-google"
      >
        Google Play
      </a>
    </div>
  )
}

export default GooglePlayButton

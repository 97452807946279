import React from "react"

function ElectrumLogo(props) {
  const {width, height} = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="86"
      // height="100"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 86 100"
    >
      <path
        fill="#1ECECA"
        fillRule="evenodd"
        d="M1.538 24.84c-2.056 1.191-2.05 4.163.013 5.344l11.708 6.706a3.557 3.557 0 011.79 3.087l-.001 31.27c0 1.103.589 2.122 1.545 2.672l16.72 9.626c2.056 1.183 4.622-.3 4.622-2.672V46.645c0-.219.026-.436.043-.654.007-.081.01-.163.01-.246l.058-19.28a3.083 3.083 0 00-1.563-2.69l-15.187-8.613a3.083 3.083 0 00-3.066.014L1.538 24.84z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#1ECECA"
        d="M81.58.353c1.738-1.003 3.91.25 3.913 2.257l.019 20.255c0 .933-.497 1.796-1.305 2.262L63.692 36.972a2.61 2.61 0 01-2.615-.004L43.58 26.804c-1.733-1.007-1.73-3.513.006-4.515L81.58.353zM81.58 31.693c1.738-1.004 3.91.25 3.913 2.257l.019 20.255c0 .933-.497 1.795-1.305 2.261L63.692 68.311a2.609 2.609 0 01-2.615-.004L43.58 58.144c-1.733-1.007-1.73-3.512.006-4.515L81.58 31.693zM81.58 63.032c1.738-1.003 3.91.25 3.913 2.257l.019 20.255c0 .933-.497 1.795-1.305 2.262L63.692 99.65a2.61 2.61 0 01-2.615-.003L43.58 89.483c-1.733-1.007-1.73-3.513.006-4.515L81.58 63.032z"
      ></path>
    </svg>
  )
}

export default ElectrumLogo

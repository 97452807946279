import React, { useContext, useEffect, useState } from 'react'
import { Modal, StarRating } from 'common'
import { Collapse, Input, Steps, message } from 'antd'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ArrowLeft, ArrowRight, ArrowRightOutline, ElectrumBlue } from 'assets/icons'
import {
  ADIRA,
  AEON,
  AboutUs,
  BRI,
  BSSLocation,
  CIMB,
  ElectrumPulsa1,
  ElectrumPulsa2,
  Future1,
  Future2,
  Future4,
  GetNowPlaceholderMobile,
  GetYoursMobileGIF,
  HowTo1,
  HowTo2,
  HowTo3,
  Mileage,
  MobileKV,
  PBB,
  PSB,
  Placeholder2,
  PlaceholderSquare,
  ProvenQuality,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  SepekanKV,
  SmartTech,
  TRANSPACIFIC,
} from 'assets/images'
import Lottie from 'react-lottie'
import { EcosystemTextMobile, HematSimulation } from 'assets/lottie'
import styled from 'styled-components'
import { CostCalculatorMobile, FooterMobile, HeaderMobile } from 'components'
import { WhatsAppOutlined } from '@ant-design/icons'

const { TextArea } = Input

const asset2Placeholder = 'https://i.postimg.cc/5t8mmyML/Frame-710.png'
const rectanglePlaceholder = 'https://knetic.org.uk/wp-content/uploads/2020/07/Pcture-Placeholder.png'

const bookingFeeURL = 'https://electrum.halosis.id/category/sub/677771/booking-fee'
const FullPBBURL = 'https://electrum.halosis.id/category/sub/677773/h3i-pbb'
const FullPSBURL = 'https://electrum.halosis.id/category/sub/677772/h3i-psb'

const MobileGetYours = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')

  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  // KV CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    { img: GetYoursMobileGIF },
    { img: GetYoursMobileGIF },
    { img: GetYoursMobileGIF },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <div
        key={index}
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={item.img}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: '#103856', marginBottom: '0.4rem' }}>
            Dapatkan <br /> Electrum Sekarang
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: '#103856', marginBottom: '1rem' }}>
            Mudah & Aman
          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: '#0B4571',
              border: '3px solid #0B4571',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Get Yours <ArrowRight fill="#fff" />
            </button>
          </div> */}
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('proklamasi-kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('proklamasi-kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }

  // CUSTOM BUTTON STYLE
  const [isHoveredPBB, setIsHoveredPBB] = useState(false)
  const [isHoveredPSB, setIsHoveredPSB] = useState(false)
  const [isHoveredCash, setIsHoveredCash] = useState(false)
  const [isHoveredLeasing, setIsHoveredLeasing] = useState(false)
  const [isHoveredBookFee, setIsHoveredBookFee] = useState(false)
  const [isHoveredFullPay, setIsHoveredFullPay] = useState(false)
  const [isHoveredAEON, setIsHoveredAEON] = useState(false)
  const [isHoveredADIRA, setIsHoveredADIRA] = useState(false)
  const [isHoveredCIMB, setIsHoveredCIMB] = useState(false)
  const [isHoveredBRI, setIsHoveredBRI] = useState(false)
  const [isHoveredTranspacific, setIsHoveredTranspacific] = useState(false)
  const buttonStyle = (isHovered) => ({
    cursor: 'pointer',
    width: '100%',
    backgroundColor: isHovered ? '#0B4571' : 'white',
    border: 'none',
    color: isHovered ? 'white' : '#0B4571',
    border: '2px solid #0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
  })
  const buttonStyleSelected = {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    border: '2px solid #0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
  }

  // CHOOSE PKG STATE
  const [batteryPackage, setBatteryPackage] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [leasingOpt, setLeasingOpt] = useState('')
  const [cashOpt, setCashOpt] = useState('')

  // CHOOSE PKG COMPONENT
  const RenderChooseBatteryPKG = () => {
    return (
      <div>
        {/* METHODS */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2.5rem' }}>
          {/* CHARGE */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem', alignItems: 'center' }}>
            <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
            <div style={{ padding: '1rem 1.25rem', backgroundColor: 'white', borderRadius: '0.65rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div>Garansi baterai 5 tahun/50 ribu km</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Home Service 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
              <div style={{ margin: '1.5rem 0 0.5rem 0' }}>
                <button
                  style={batteryPackage === 'PBB' ? buttonStyleSelected : buttonStyle(isHoveredPBB)}
                  // onMouseEnter={() => setIsHoveredPBB(true)}
                  // onMouseLeave={() => setIsHoveredPBB(false)}
                  onClick={() => setBatteryPackage('PBB')}
                >
                  Pilih Paket PBB
                </button>
              </div>
            </div>
          </div>
          {/* SWAP */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem', alignItems: 'center' }}>
            <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
            <div style={{ padding: '1rem 1.25rem', backgroundColor: 'white', borderRadius: '0.65rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> sewa baterai selama 18 bulan</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
              <div style={{ margin: '1.5rem 0 0.5rem 0' }}>
                <button
                  style={batteryPackage === 'PSB' ? buttonStyleSelected : buttonStyle(isHoveredPSB)}
                  // onMouseEnter={() => setIsHoveredPSB(true)}
                  // onMouseLeave={() => setIsHoveredPSB(false)}
                  onClick={() => setBatteryPackage('PSB')}
                >
                  Pilih Paket PSB
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderChoosePaymentMethod = () => {
    return (
      <div>
        {/* METHODS */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          {/* CASH */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', textAlign: 'center', marginBottom: '0.5rem' }}>
              Tunai
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Tersedia metode pembayaran debit dan kredit.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={paymentMethod === 'cash' ? buttonStyleSelected : buttonStyle(isHoveredCash)}
                // onMouseEnter={() => setIsHoveredCash(true)}
                // onMouseLeave={() => setIsHoveredCash(false)}
                onClick={() => setPaymentMethod('cash')}
              >
                Pilih Menggunakan Tunai
              </button>
            </div>
          </div>
          {/* LEASING */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', textAlign: 'center', marginBottom: '0.5rem' }}>
              Leasing
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Ajukan leasing ke perusahaan terpercaya Electrum.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={paymentMethod === 'leasing' ? buttonStyleSelected : buttonStyle(isHoveredLeasing)}
                // onMouseEnter={() => setIsHoveredLeasing(true)}
                // onMouseLeave={() => setIsHoveredLeasing(false)}
                onClick={() => setPaymentMethod('leasing')}
              >
                Pilih Menggunakan Leasing
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderChooseCash = () => {
    return (
      <div>
        {/* METHODS */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          {/* BOOKING FEE */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', textAlign: 'center', marginBottom: '0.5rem' }}>
              Biaya Pemesanan
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Amankan stok motor listrik Electrum sebelum kehabisan dengan biaya pemesanan yang rendah.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={cashOpt === 'bookfee' ? buttonStyleSelected : buttonStyle(isHoveredBookFee)}
                // onMouseEnter={() => setIsHoveredBookFee(true)}
                // onMouseLeave={() => setIsHoveredBookFee(false)}
                onClick={() => window.open(bookingFeeURL, '_blank')}
              >
                Bayar Booking Fee
              </button>
            </div>
          </div>
          {/* FULL PAYMENT */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', textAlign: 'center', marginBottom: '0.5rem' }}>
              Pembayaran Penuh
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Lebih tangguh dengan pembayaran penuh.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={cashOpt === 'full' ? buttonStyleSelected : buttonStyle(isHoveredFullPay)}
                // onMouseEnter={() => setIsHoveredFullPay(true)}
                // onMouseLeave={() => setIsHoveredFullPay(false)}
                onClick={() => {
                  if (batteryPackage === 'PBB') {
                    window.open(FullPBBURL, '_blank')
                  } else {
                    window.open(FullPSBURL, '_blank')
                  }
                }}
              >
                Beli H3i
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderChooseLease = () => {
    return (
      <div>
        {/* HEADLINE */}
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#103856', marginBottom: '1.5rem' }}>
          Ajukan leasing Anda kepada perusahan terpercaya berikut, motor listrik Electrum aman dikirim ke alamat Anda. 
        </div>
        {/* LEASING LIST */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          {/* AEON */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={AEON} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                AEON Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai dari 1,5 juta
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'aeon' ? buttonStyleSelected : buttonStyle(isHoveredAEON)}
                // onMouseEnter={() => setIsHoveredAEON(true)}
                // onMouseLeave={() => setIsHoveredAEON(false)}
                onClick={() => window.open('https://drive.google.com/file/d/1hyLGGakdD4iJyS51NkSowfKpXhdlRqT4/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          {/* ADIRA */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={ADIRA} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                ADIRA Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                Cicilan mulai Rp 500 ribuan/bulan
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'adira' ? buttonStyleSelected : buttonStyle(isHoveredADIRA)}
                // onMouseEnter={() => setIsHoveredADIRA(true)}
                // onMouseLeave={() => setIsHoveredADIRA(false)}
                onClick={() => window.open('https://drive.google.com/file/d/1voFH8UQgdHXIpyHOlwjjwNShK8p6UDHI/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          {/* CIMB */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={CIMB} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                CIMB Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai 0%
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'cimb' ? buttonStyleSelected : buttonStyle(isHoveredCIMB)}
                // onMouseEnter={() => setIsHoveredCIMB(true)}
                // onMouseLeave={() => setIsHoveredCIMB(false)}
                onClick={() => window.open('https://drive.google.com/file/d/15RiLU63vpzk1knwxt892eH3T91Clpnpj/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          {/* BRI */}
          {/* <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={BRI} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                BRI Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai dari 1,5 juta
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'bri' ? buttonStyleSelected : buttonStyle(isHoveredBRI)}
                // onMouseEnter={() => setIsHoveredBRI(true)}
                // onMouseLeave={() => setIsHoveredBRI(false)}
                onClick={() => setLeasingOpt('bri')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div> */}
          {/* TRANSPACIFIC */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '0.75rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={TRANSPACIFIC} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                Transpacific Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai dari 3,5 juta
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'transpacific' ? buttonStyleSelected : buttonStyle(isHoveredTranspacific)}
                // onMouseEnter={() => setIsHoveredTranspacific(true)}
                // onMouseLeave={() => setIsHoveredTranspacific(false)}
                onClick={() => window.open('https://drive.google.com/file/d/1dbTJKErTkOE2N_t4o5ja2J1N-SLNCybZ/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
        </div>
        {/* CONTACT US */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '2rem',
          alignItems: 'center',
          border: '2px solid #1ECECA',
          borderRadius: '0.75rem',
          padding: '2rem',
          marginTop: '1rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856', textAlign: 'center' }}>
            Hubungi kami untuk rate leasing terbaik!
          </div>
          <div>
            <button
              style={{
                cursor: 'pointer',
                width: '100%',
                backgroundColor: '#25D366',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
              }}
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            >
              Hubungi Whatsapp &nbsp; <WhatsAppOutlined />
            </button>
          </div>
        </div>
      </div>
    )
  }

  // CUSTOM JS FUNCTION
  const capitalizeFirstLetter = (str) => {
    return String(str).charAt(0).toUpperCase() + String(str).slice(1);
  }
  const batterPkgNaming = (pkg) => {
    let naming
    switch (pkg) {
      case 'PBB':
        return naming = 'Beli Baterai'
      case 'PSB':
        return naming = 'Sewa Baterai'
      default:
        return naming = null
    }
  }

  // STEPS CONTENT
  const steps = [
    {
      title: 'Pilih Paket Baterai',
      description: batteryPackage !== '' ? `Pilihan: \n ${batterPkgNaming(batteryPackage)}` : null,
      content: <RenderChooseBatteryPKG />,
    },
    {
      title: 'Metode Pembayaran',
      description: paymentMethod !== '' ? `Pilihan: ${capitalizeFirstLetter((paymentMethod === 'cash' ? 'Tunai' : paymentMethod))}` : null,
      content: <RenderChoosePaymentMethod />,
    },
    {
      title: paymentMethod === 'leasing' ? 'Pilihan Leasing' : 'Pilihan Pembayaran',
      content: paymentMethod === 'cash'
        ? <RenderChooseCash />
        : <RenderChooseLease />,
    },
  ]
  const [current, setCurrent] = useState(0)
  const getStepClass = (index) => {
    if (index !== current) {
      return 'next-step'
    }
    return ''
  }
  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }
  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
    description: item.description,
    className: getStepClass(index),
  }))
  const canProceed = (step) => {
    if (step === 0) return batteryPackage !== ''
    if (step === 1) return paymentMethod !== ''
    if (step === 2) return cashOpt !== ''
    return false
  }
  const handleOptionSelect = (option) => {
    if (current === 0) {
      setBatteryPackage(option)
    } else if (current === 1) {
      setPaymentMethod(option)
    } else if (current === 2) {
      setCashOpt(option)
    }
  }
  const RenderStepsContent = () => {
    return (
      <div style={{ padding: '1.5rem', border: '2px solid #D7F3F3', borderRadius: '2rem' }}>
        <Steps current={current} items={items} direction='horizontal' style={{ marginBottom: '1.5rem' }} />
        <div>
          {/* {steps[current].content} */}
          {React.cloneElement(steps[current].content, { handleOptionSelect })}
        </div>
        <div style={{ marginTop: '2rem', display: 'flex', columnGap: '0.75rem' }}>
          {current > 0 && (
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: 'none',
                color: '#0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                border: '2px solid #0B4571',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => {
                prev()
                document.getElementById('steps-section')?.scrollIntoView()
              }}
            >
              <ArrowLeft fill="#0B4571" /> Kembali
            </button>
          )}
          {current < steps.length - 1 && (
            <button
              style={{
                cursor: canProceed(current) ? 'pointer' : 'not-allowed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
                opacity: canProceed(current) ? 1 : 0.5,
              }}
              onClick={() => {
                canProceed(current) && next()
                document.getElementById('steps-section')?.scrollIntoView()
              }}
              disabled={!canProceed(current)}
            >
              Berikutnya <ArrowRight fill="#fff" />
            </button>
          )}
        </div>
      </div>
    )
  }

  // LEASING LIST
  const leasingList = [
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
  ]
  const renderLeasingList = () => {
    return leasingList.map((item, index) => (
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '3rem' }}>
        <img loading='lazy'  alt={`lease-${index}`} src={item.img} style={{ width: '100%', borderRadius: '1.25rem', marginBottom: '1rem' }} />
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', marginBottom: '0.85rem' }}>
          {item.lease}
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', marginBottom: '1rem' }}>
          {item.desc}
        </div>
        <button style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#0B4571',
          border: 'none',
          color: 'white',
          fontFamily: 'Gilroy-SemiBold',
          padding: '0.75rem 1.5rem',
          borderRadius: '0.5rem',
          fontSize: '1rem',
          width: 'fit-content',
        }}>
          See More <ArrowRight fill="#fff" />
        </button>
      </div>
    ))
  }

  const ColoredText = styled.span`
    background: linear-gradient(to right, #ADEEEC, #1CA6A3);
    color: transparent;
    background-clip: text;
  `

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          // backgroundColor: '#F4F7FA',
          backgroundColor: 'white',
        }}
      >
        <HeaderMobile />

        {/* KV CAROUSEL */}
        {/* <div style={{ marginBottom: '2rem' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <div
              id="proklamasi-kv-carousel"
              style={{
                margin: '0 auto',
                backgroundColor: 'white',
                display: 'flex',
                overflowX: 'scroll',
                scrollSnapType: 'x mandatory',
                scrollSnapStop: 'always',
              }}
              onScroll={(e) => {
                const container = e.target
                const scrollPosition = container.scrollLeft
                const containerWidth = container.clientWidth
                const newIndex = Math.round(scrollPosition / containerWidth)
                setCurrentIndex(newIndex)
              }}
            >
              {renderKVCarousel()}
              <button
                onClick={() => handleScrollCarousel(-1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === 0 ? 'none' : 'block',
                }}
              >
                &#8249;
              </button>
              <button
                onClick={() => handleScrollCarousel(1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === KVList.length-1 ? 'none' : 'block',
                }}
              >
                &#8250;
              </button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              position: 'relative',
            }}
          >
            {KVList.map((item, index) => (
              <div
                key={index}
                onClick={() => handleIndicatorClick(index)}
                style={{
                  width: '30px',
                  height: '6.5px',
                  borderRadius: '50rem',
                  backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
              ></div>
            ))}
          </div>
        </div> */}

        {/* SINGLE BANNER */}
        <div
          style={{
            scrollSnapAlign: 'start',
            backgroundSize: 'cover',
            // height: '100%',
            maxWidth: '100%',
            flexShrink: 0,
            position: 'relative',
          }}
        >
          <img
            effect='blur'
            alt='top-banner'
            src={GetYoursMobileGIF}
            loading='lazy'
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
          />
          <div style={{
            width: '100%',
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -10%)',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: '#103856', marginBottom: '0.4rem' }}>
              Dapatkan <br /> Electrum Sekarang
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: '#103856', marginBottom: '1rem' }}>
              Mudah & Aman
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                See More <ArrowRight fill="#fff" />
              </button>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: 'none',
                color: '#0B4571',
                border: '3px solid #0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Get Yours <ArrowRight fill="#fff" />
              </button>
            </div> */}
          </div>
        </div>

        {/* CHOOSE PKG */}
        <div style={{ padding: '3rem 1.25rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center', marginBottom: '0.85rem' }}>
            Pilih Paketnya, Bungkus Motornya
          </div>
          <div id='steps-section' style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', textAlign: 'center', marginBottom: '2.5rem' }}>
            Tersedia pilihan paket Electrum yang menarik melalui variasi metode pembayaran yang kamu inginkan.
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            {RenderStepsContent()}
          </div>
        </div>

        {/* SEPEKAN */}
        <div>
          <img loading='lazy' 
            alt='sepekan-mobile'
            src={SepekanKV}
            onClick={() => navigate('/sepekan-form')}
            style={{ width: '100%', marginBottom: '3rem' }}
          />
        </div>

        {/* HOW IT WORKS */}
        {/* <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', marginBottom: '0.75rem' }}>
            Sesederhana ini cara kerjanya
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#6A8192', marginBottom: '3rem' }}>
            This section explains the available options to get H3, through ownership model (leasing) and rental.
          </div>
          {renderLeasingList()}
        </div> */}

        {/* FORM */}
        {/* <div style={{ padding: '2rem' }}>
          <img loading='lazy'  alt='placeholder' src={PlaceholderSquare} style={{ width: '100%', borderRadius: '1.25rem', marginBottom: '1.5rem' }} />
          <div style={{ padding: '1.5rem', backgroundColor: 'white', borderRadius: '1.25rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856', marginBottom: '1.5rem' }}>
              Heading
            </div>
            <div style={{ display: 'flex', columnGap: '1.5rem', marginBottom: '1.25rem' }}>
              <div>
                <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                  Nama Depan
                </div>
                <Input name='fistName' />
              </div>
              <div>
                <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                  Nama Belakang
                </div>
                <Input name='lastName' />
              </div>
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nomor Telefon
              </div>
              <Input name='phoneNumber' type='number' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Alamat
              </div>
              <TextArea rows={4} name='address' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Label
              </div>
              <Input name='label' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Label
              </div>
              <Input name='label' />
            </div>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Button Text
            </button>
          </div>
        </div> */}

        <FooterMobile />
      </div>
    </div>
  )
}

export default MobileGetYours

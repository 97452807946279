import React, { useContext } from 'react'
import { ElectrumBlue } from 'assets/icons'
import { LanguageSwitcher } from 'common'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // LANGUAGE
  const { locale, selectLanguage } = useContext(Context)
  const handleChange = (e) => {
    selectLanguage(e.target.value)
  }
  const defaults = require('languages/id.json')

  // ROUTES
  const routesArr = [
    {
      name: 'header_product',
      route: '/products',
    },
    {
      name: 'header_energy',
      route: '/energy',
    },
    {
      name: 'header_getYours',
      route: '/get-yours',
    },
    {
      name: 'header_aboutUs',
      route: '/about-us',
    },
    {
      name: 'header_news',
      route: '/news',
    },
  ]
  const renderRouteNavigations = () => {
    return routesArr.map((item, index) => (
      <div
        style={{
          cursor: 'pointer',
          fontFamily: pathname === item.route ? 'Gilroy-Bold' : 'Gilroy-Medium',
          color: pathname === item.route ? '#40C0C0' : '#000',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          borderBottom: pathname === item.route ? '0.5rem solid #1ECECA' : 'none',
          borderTop: pathname === item.route ? '0.5rem solid #fff' : 'none',
        }}
        onClick={() => navigate(item.route)}
      >
        <FormattedMessage
          id={item.name}
          defaultMessage={defaults[item.name]}
        />
      </div>
    ))
  }

  return (
    <div style={{
      padding: '0rem 12.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      position: 'sticky',
      top: '0',
      zIndex: '9999',
      height: '5.5rem',
    }}>

      {/* LEFT SIDE */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '1.5rem',
      }}>
        <div
          style={{
            cursor: 'pointer',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            borderBottom: pathname === '/' ? '0.5rem solid #1ECECA' : 'none',
            borderTop: pathname === '/' ? '0.5rem solid #fff' : 'none',
          }}
          onClick={() => navigate('/')}
        >
          <ElectrumBlue />
        </div>
        {renderRouteNavigations()}
      </div>

      {/* RIGHT SIDE */}
      {/* <div style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '2rem',
      }}>
        <div style={{ cursor: 'pointer', fontFamily: 'Gilroy-Medium' }}>
          <FormattedMessage
            id="header_signUp"
            defaultMessage={defaults["header_signUp"]}
          />
        </div>
        <div style={{ cursor: 'pointer', fontFamily: 'Gilroy-Medium' }}>
          <FormattedMessage
            id="header_login"
            defaultMessage={defaults["header_login"]}
          />
        </div>
        <LanguageSwitcher
          value={locale}
          onChange={handleChange}
        />
      </div> */}

    </div>
  )
}

export default Header

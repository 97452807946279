import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ElectrumWhite, WhatsappIcon } from 'assets/icons'
import { InstagramFilled, MailFilled, TikTokOutlined, WhatsAppOutlined } from '@ant-design/icons'

const Footer = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div style={{
      padding: '0 2rem 2rem 2rem',
      backgroundColor: pathname === '/products' ? '#F4F7FA' : 'initial',
    }}>

      {/* FOOTER */}
      <div style={{
        borderRadius: '0 0 1.5rem 1.5rem',
        // borderRadius: '1.5rem',
        padding: '3.5rem',
        backgroundColor: '#0C2536',
      }}>
        {/* LINKS */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '3rem' }}>
          {/* NAVIGATIONS */}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '5rem' }}>
            {/* PAGES */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.55rem' }}>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: 'white', marginBottom: '0.45rem' }}>
                Electrum
              </div>
              <div
                onClick={() => navigate('/')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Beranda
              </div>
              <div
                onClick={() => navigate('/products')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Produk
              </div>
              <div
                onClick={() => navigate('/energy')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Energi
              </div>
              <div
                onClick={() => navigate('/get-yours')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Dapatkan Milikmu
              </div>
              <div
                onClick={() => navigate('/about-us')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Tentang Kami
              </div>
              <div
                onClick={() => navigate('/news')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Berita
              </div>
            </div>
            {/* OTHERS */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.55rem' }}>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: 'white', marginBottom: '0.45rem' }}>
                Quick Links
              </div>
              <div
                onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Book Test Ride
              </div>
              <div
                onClick={() => navigate('/privacy-policy')}
                style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#4D7FA4', cursor: 'pointer' }}
              >
                Privacy Policy
              </div>
            </div>
          </div>
          {/* SOCIALS */}
          <div style={{ display: 'flex', columnGap: '1.25rem', height: 'fit-content' }}>
            <div
              onClick={() => window.open('https://www.instagram.com/electrum.id', '_blank')}
              style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
            >
              <InstagramFilled style={{ fontSize: '1.5rem', color: '#103856' }} />
            </div>
            <div
              onClick={() => window.open('https://www.tiktok.com/@electrum.indonesia', '_blank')}
              style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
            >
              <TikTokOutlined style={{ fontSize: '1.5rem', color: '#103856' }} />
            </div>
            <div
              onClick={() => window.open('/')}
              style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
            >
              <MailFilled style={{ fontSize: '1.5rem', color: '#103856' }} />
            </div>
            <div
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
              style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
            >
              <WhatsAppOutlined style={{ fontSize: '1.5rem', color: '#103856' }} />
            </div>
          </div>
        </div>
        {/* DIVIDER */}
        <div style={{ border: '1px solid #3C6889', marginBottom: '1.875rem' }} />
        {/* BOTTOM */}
        <div>
          <ElectrumWhite width='200' height='60' />
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
            It's a Smart Move
          </div>
        </div>
      </div>

      {/* WHATSAPP */}
      <div
        style={{
          position: 'fixed',
          bottom: '2rem',
          right: '2.25rem',
          cursor: 'pointer',
        }}
        onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
      >
        <WhatsappIcon width='80' height='80' />
      </div>

    </div>
  )
}

export default Footer

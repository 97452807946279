import React from "react"

const ElectrumMobile = ({ width="200", height="69" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 482 109"
    >
      <path
        fill="#1ECECA"
        fillRule="evenodd"
        d="M397.538 32.84c-2.056 1.191-2.049 4.163.013 5.344l11.708 6.706a3.557 3.557 0 011.789 3.087v31.27c0 1.103.589 2.122 1.545 2.672l16.72 9.626c2.056 1.183 4.622-.3 4.622-2.672V54.645c0-.219.026-.436.043-.654.007-.081.01-.163.01-.246l.058-19.28a3.084 3.084 0 00-1.562-2.69l-15.188-8.613a3.084 3.084 0 00-3.066.014l-16.692 9.665z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#1ECECA"
        d="M477.579 8.353c1.739-1.003 3.912.25 3.914 2.257l.019 20.255a2.61 2.61 0 01-1.305 2.262l-20.515 11.845a2.61 2.61 0 01-2.615-.004L439.58 34.804c-1.734-1.007-1.73-3.513.006-4.515l37.993-21.936zM477.579 39.693c1.739-1.004 3.912.25 3.914 2.257l.019 20.255a2.61 2.61 0 01-1.305 2.261l-20.515 11.845a2.609 2.609 0 01-2.615-.004L439.58 66.144c-1.734-1.007-1.73-3.512.006-4.515l37.993-21.935zM477.579 71.032c1.739-1.003 3.912.25 3.914 2.257l.019 20.255a2.61 2.61 0 01-1.305 2.262l-20.515 11.844a2.608 2.608 0 01-2.615-.003L439.58 97.483c-1.734-1.007-1.73-3.513.006-4.515l37.993-21.936z"
      ></path>
      <path
        fill="#fff"
        d="M15.12 67.27c1.62 5.94 6.57 8.46 12.69 8.46 4.5 0 8.1-1.89 10.08-4.5l9.36 5.4c-4.23 6.12-10.98 9.63-19.62 9.63-15.12 0-24.66-10.35-24.66-23.76 0-13.41 9.63-23.76 23.76-23.76 13.32 0 22.77 10.53 22.77 23.76 0 1.71-.18 3.24-.45 4.77H15.12zm-.18-9h22.95c-1.44-6.48-6.3-9.09-11.16-9.09-6.21 0-10.44 3.33-11.79 9.09zM57.605 85V19.3h11.61V85h-11.61zM89.65 67.27c1.62 5.94 6.57 8.46 12.69 8.46 4.5 0 8.1-1.89 10.08-4.5l9.36 5.4c-4.23 6.12-10.98 9.63-19.62 9.63-15.12 0-24.66-10.35-24.66-23.76 0-13.41 9.63-23.76 23.76-23.76 13.32 0 22.77 10.53 22.77 23.76 0 1.71-.18 3.24-.45 4.77h-33.93zm-.18-9h22.95c-1.44-6.48-6.3-9.09-11.16-9.09-6.21 0-10.44 3.33-11.79 9.09zm63.734 27.99c-13.68 0-23.76-10.35-23.76-23.76 0-13.41 10.08-23.76 23.76-23.76 8.82 0 16.47 4.68 20.16 11.61l-9.99 5.85c-1.8-3.69-5.67-6.03-10.26-6.03-6.93 0-12.06 5.13-12.06 12.33 0 7.11 5.13 12.24 12.06 12.24 4.68 0 8.55-2.25 10.35-5.94l10.08 5.76c-3.87 7.02-11.52 11.7-20.34 11.7zm52.22-35.1h-10.17v18.72c0 4.86 3.51 4.95 10.17 4.59V85c-16.29 1.8-21.78-2.97-21.78-15.12V51.16h-7.83V40h7.83v-9.09l11.61-3.51V40h10.17v11.16zm20.499-3.42c2.34-6.12 8.01-8.64 13.68-8.64v12.96c-6.03-.72-13.68 1.98-13.68 11.43V85h-11.61V40h11.61v7.74zm50.17-7.74h11.61v45h-11.61v-5.04c-2.7 4.05-7.38 6.3-13.41 6.3-9.54 0-17.01-6.66-17.01-18.63V40h11.61v26.19c0 6.12 3.69 9.27 8.91 9.27 5.67 0 9.9-3.33 9.9-11.16V40zm72.013-1.26c10.26 0 17.01 7.29 17.01 18.54V85h-11.61V58.09c0-5.31-2.7-8.55-7.56-8.55-5.13 0-8.37 3.42-8.37 10.17V85h-11.61V58.09c0-5.31-2.7-8.55-7.56-8.55-4.95 0-8.46 3.42-8.46 10.17V85h-11.61V40h11.61v4.77c2.52-3.78 6.75-6.03 12.51-6.03 5.58 0 9.72 2.34 12.24 6.48 2.79-4.05 7.29-6.48 13.41-6.48z"
      ></path>
    </svg>
  )
}

export default ElectrumMobile
import React, { useEffect, useState } from "react"
import { IntlProvider } from "react-intl"
import * as languages from "languages"
import { useLocation } from "react-router-dom" // Importing useLocation from React Router DOM

export const Context = React.createContext()

const Wrapper = ({ children }) => {
  const [locale, setLocale] = useState('id')
  const [messages, setMessages] = useState(languages)

  useEffect(() => {
    const storedLocale = localStorage.getItem("selectedLocale")
    if (storedLocale && languages[storedLocale]) {
      setLocale(storedLocale)
      setMessages(languages[storedLocale])
    } else {
      setLocale("id") // Set "id" as default if no locale is stored
      setMessages(languages["id"])
      localStorage.setItem("selectedLocale", "id")
    }
  }, [])

  function selectLanguage(newLocale) {
    setLocale(newLocale)
    setMessages(languages[newLocale])
    localStorage.setItem("selectedLocale", newLocale)
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale} onError={() => null}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export default Wrapper

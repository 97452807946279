import React, { useContext, useState } from 'react'
import { Footer, Header } from 'components'
import { Context } from 'helpers/language-provider'

import { H3, H5 } from 'assets/images'
import ProductH3 from './h3'
import ProductH5 from './h5'

const Product = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')

  const [selectedProduct, setSelectedProduct] = useState('H3')

  return (
    <div>
      <Header />

      {/* PRODUCT TAB */}
      {/* <div style={{
        backgroundColor: '#F4F9FA',
        display: 'flex',
        justifyContent: 'center',
        columnGap: '7.5rem',
        paddingTop: '1rem',
      }}>
        <div
          onClick={() => setSelectedProduct('H3')}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy'  src={H3} alt='H3' height={90} />
          <div
            style={{
              fontFamily: selectedProduct === 'H3' ? 'Gilroy-Bold' : 'Gilroy-SemiBold',
              color: selectedProduct === 'H3' ? '#40C0C0' : '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom: selectedProduct === 'H3' ? '0.45rem solid #1ECECA' : 'none',
            }}
          >
            ELECTRUM H3
          </div>
        </div>
        <div
          onClick={() => setSelectedProduct('H5')}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy'  src={H5} alt='H5' height={90} />
          <div
            style={{
              fontFamily: selectedProduct === 'H5' ? 'Gilroy-Bold' : 'Gilroy-SemiBold',
              color: selectedProduct === 'H5' ? '#40C0C0' : '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom: selectedProduct === 'H5' ? '0.45rem solid #1ECECA' : 'none',
            }}
          >
            ELECTRUM H5
          </div>
        </div>
      </div> */}

      {
        selectedProduct === 'H3'
        ? <ProductH3 />
        : <ProductH5 />
      }

      <Footer />
    </div>
  )
}

export default Product

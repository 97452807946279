import BannerPlaceholder from 'assets/images/banner-placeholder.png'
// import MapPlaceholder from 'assets/images/map-placeholder.png'
// import Placeholder1 from 'assets/images/placeholder1.png'
// import Placeholder2 from 'assets/images/placeholder2.png'
// import Placeholder3 from 'assets/images/placeholder3.png'
// import PlaceholderSquare from 'assets/images/placeholder-square.png'
// import PlaceholderRectangle1 from 'assets/images/placeholder-rectangle1.png'
// import Reviewer1 from 'assets/images/reviewer1.png'
// import Reviewer2 from 'assets/images/reviewer2.png'
// import Reviewer3 from 'assets/images/reviewer3.png'
// import H3 from 'assets/images/H3.png'
// import H5 from 'assets/images/H5.png'
// import ProductLarge from 'assets/images/product-large.png'
// import ProductSmall from 'assets/images/product-small.png'
// import ProductEV from 'assets/images/product-ev.png'
// import Feature from 'assets/images/feature.png'
// import EnergyBanner from 'assets/images/energy-banner.png'
// import EnergyBannerMobile from 'assets/images/energy-banner-mobile.png'
// import HowTo1 from 'assets/images/howto1.png'
// import HowTo2 from 'assets/images/howto2.png'
// import HowTo3 from 'assets/images/howto3.png'
// import Excellent from 'assets/images/excellent.png'
// import ProvenQuality from 'assets/images/provenquality.png'
// import ProvenQuality2 from 'assets/images/provenquality2.png'
// import Mileage from 'assets/images/mileage.png'
// import Riders from 'assets/images/riders.png'
// import TwoWheeler from 'assets/images/twowheeler.png'
// import BSS from 'assets/images/BSS.png'
// import BSS2 from 'assets/images/BSS2.png'
// import BSSLocation from 'assets/images/BSSLocation.png'
// import BSSLocation2 from 'assets/images/BSSLocation2.png'
// import BSSLocationMobile from 'assets/images/BSSLocation-mobile.png'
// import SaveCost from 'assets/images/save-cost.png'
// import SmartTech from 'assets/images/smart-tech.png'
// import GetNowPlaceholder from 'assets/images/getNowPlaceholder.png'
// import GetNowPlaceholderMobile from 'assets/images/getNowPlaceholderMobile.png'
// import MobileKV from 'assets/images/mobile-kv.png'
// import AboutUs from 'assets/images/aboutus.png'
// import AboutUsKV from 'assets/images/aboutus-kv.png'
// import AboutUsKVMobile from 'assets/images/aboutus-kv-mobile.png'
// import AboutUsKV2 from 'assets/images/aboutus-kv2.png'
// import AboutUsKV2Mobile from 'assets/images/aboutus-kv2-mobile.png'
// import AboutUsSpirit from 'assets/images/aboutus-spirit.png'
// import AboutUsSpiritMobile from 'assets/images/aboutus-spirit-mobile.png'
// import CoinLeft from 'assets/images/coinLeft.png'
// import CoinRight from 'assets/images/coinRight.png'
// import RectanglePlaceholder from 'assets/images/rectangle-placeholder.png'
// import EfficientPlaceholder from 'assets/images/efficient-placeholder.png'
// import TechDesign1 from 'assets/images/techdesign1.png'
// import TechDesign2 from 'assets/images/techdesign2.png'
// import TechDesign3 from 'assets/images/techdesign3.png'
// import AboutTD1 from 'assets/images/abouttd1.png'
// import AboutTD2 from 'assets/images/abouttd2.png'
// import AboutTD3 from 'assets/images/abouttd3.png'
// import AboutTD4 from 'assets/images/abouttd4.png'
// import ColorDerawanBlue from 'assets/images/color_derawanblue.png'
// import ColorIvoryWhite from 'assets/images/color_ivorywhite.png'
// import ColorRedCherry from 'assets/images/color_redcherry.png'
// import ColorAmber from 'assets/images/color_amber.png'
// import ColorIceNavy from 'assets/images/color_icenavy.png'
// import ColorViridianGreen from 'assets/images/color_viridiangreen.png'
// import ColorBeige from 'assets/images/color_beige.png'
// import ColorGrey from 'assets/images/color_grey.png'
// import MickeySquare from 'assets/images/mickey-square.png'
// import MickeyPortrait from 'assets/images/mickey-portrait.png'
// import ElectrumPulsa1 from 'assets/images/electrum-pulsa1.png'
// import ElectrumPulsa2 from 'assets/images/electrum-pulsa2.png'
// import Future1 from 'assets/images/future1.png'
// import Future2 from 'assets/images/future2.png'
// import Future3 from 'assets/images/future3.png'
// import Future4 from 'assets/images/future4.png'
// import Future5 from 'assets/images/future5.png'
// import SepekanKV from 'assets/images/sepekan.png'
// import SepekanKVMobile from 'assets/images/sepekan-mobile.png'
// import SepekanKVBanner from 'assets/images/sepekan-banner.jpg'
// import PackageBG from 'assets/images/package-bg.png'
// import PBB from 'assets/images/pbb.png'
// import PSB from 'assets/images/psb.png'
// import Swaps from 'assets/images/swaps.png'
// import BatteryCharge from 'assets/images/battery-charge.png'
// import BatterySwap from 'assets/images/battery-swap.png'
// import BatteryChargeDark from 'assets/images/battery-charge-dark.png'
// import BatterySwapDark from 'assets/images/battery-swap-dark.png'
// import IntroProduct from 'assets/images/intro-product.png'
// import IntroProductMobile from 'assets/images/intro-product-mobile.png'
// import Understand1 from 'assets/images/understand1.png'
// import Understand2 from 'assets/images/understand2.png'
// import Understand3 from 'assets/images/understand3.png'
// import GradientDark from 'assets/images/gradient-dark.png'
// import IntroH3 from 'assets/images/intro-h3.png'
// import IntroH3New from 'assets/images/intro-h3-new.png'
// import IntroH3NewV2 from 'assets/images/intro-h3-new-v2.png'
// import HomeKV from 'assets/images/home-kv.png'
// import HomeKVMobile from 'assets/images/home-kv-mobile.png'
// import ProductKV from 'assets/images/product-kv.png'
// import ProductKVMobile from 'assets/images/product-kv-mobile.png'
// import Mission1 from 'assets/images/mission1.png'
// import Mission2 from 'assets/images/mission2.png'
// import Mission3 from 'assets/images/mission3.png'
// import ElectrumYear from 'assets/images/electrum-year.png'
// import Showroom from 'assets/images/showroom.png'
// import GetYoursGIF from 'assets/images/get-yours-kv.gif'
// import GetYoursMobileGIF from 'assets/images/get-yours-kv-mobile.gif'
// import AEON from 'assets/images/aeon.png'
// import ADIRA from 'assets/images/adira.png'
// import CIMB from 'assets/images/cimb.png'
// import BRI from 'assets/images/bri.png'
// import TRANSPACIFIC from 'assets/images/transpacific.png'
import SepekanSquare from 'assets/images/sepekan-square.jpg'
import TNCSeparator from 'assets/images/tnc-separator.png'

export {
  BannerPlaceholder,
//   MapPlaceholder,
//   Placeholder1,
//   Placeholder2,
//   Placeholder3,
//   PlaceholderSquare,
//   PlaceholderRectangle1,
//   Reviewer1,
//   Reviewer2,
//   Reviewer3,
//   H3,
//   H5,
//   ProductLarge,
//   ProductSmall,
//   ProductEV,
//   Feature,
//   EnergyBanner,
//   EnergyBannerMobile,
//   HowTo1,
//   HowTo2,
//   HowTo3,
//   Excellent,
//   ProvenQuality,
//   ProvenQuality2,
//   Mileage,
//   Riders,
//   TwoWheeler,
//   BSS,
//   BSS2,
//   BSSLocation,
//   BSSLocation2,
//   BSSLocationMobile,
//   SaveCost,
//   SmartTech,
//   GetNowPlaceholder,
//   GetNowPlaceholderMobile,
//   MobileKV,
//   AboutUs,
//   AboutUsKV,
//   AboutUsKVMobile,
  // AboutUsKV2,
//   AboutUsKV2Mobile,
//   AboutUsSpirit,
//   AboutUsSpiritMobile,
//   CoinLeft,
//   CoinRight,
//   RectanglePlaceholder,
//   EfficientPlaceholder,
//   TechDesign1,
//   TechDesign2,
//   TechDesign3,
//   AboutTD1,
//   AboutTD2,
//   AboutTD3,
//   AboutTD4,
//   ColorDerawanBlue,
//   ColorIvoryWhite,
//   ColorRedCherry,
//   ColorAmber,
//   ColorIceNavy,
//   ColorViridianGreen,
//   ColorBeige,
//   ColorGrey,
//   MickeySquare,
//   MickeyPortrait,
//   ElectrumPulsa1,
//   ElectrumPulsa2,
//   Future1,
//   Future2,
//   Future3,
//   Future4,
//   Future5,
//   SepekanKV,
//   SepekanKVMobile,
//   SepekanKVBanner,
//   PackageBG,
//   PBB,
//   PSB,
//   Swaps,
//   BatteryCharge,
//   BatterySwap,
//   BatteryChargeDark,
//   BatterySwapDark,
//   IntroProduct,
//   IntroProductMobile,
//   Understand1,
//   Understand2,
//   Understand3,
//   GradientDark,
//   IntroH3,
//   IntroH3New,
  // IntroH3NewV2,
//   HomeKV,
//   HomeKVMobile,
//   ProductKV,
//   ProductKVMobile,
//   Mission1,
//   Mission2,
//   Mission3,
//   ElectrumYear,
//   Showroom,
//   GetYoursGIF,
//   GetYoursMobileGIF,
//   AEON,
//   ADIRA,
//   CIMB,
//   BRI,
//   TRANSPACIFIC,
  SepekanSquare,
  TNCSeparator,
}

// export const BannerPlaceholder = ''
export const MapPlaceholder = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096175/get-yours-kv_reptmk.gif'
export const Placeholder1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096557/placeholder1_ctlbgj.png'
export const Placeholder2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096532/placeholder2_wszhpr.png'
export const Placeholder3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096533/placeholder3_ug7bqk.png'
export const PlaceholderSquare = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096371/placeholder-square_bb3xoe.png'
export const PlaceholderRectangle1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096357/placeholder-rectangle1_hxjvgz.png'
export const Reviewer1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096140/reviewer1_yscub3.png'
export const Reviewer2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096140/reviewer2_endnho.png'
export const Reviewer3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096141/reviewer3_bgybxp.png'
export const H3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096524/H3_z2jg4e.png'
export const H5 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096525/H5_zhl3pr.png'
export const ProductLarge = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096537/product-large_t2n6m1.png'
export const ProductSmall = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096536/product-small_eg1gmc.png'
export const ProductEV = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096542/product-ev_picxe0.png'
export const Feature = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096512/feature_rfptyr.png'
export const EnergyBanner = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096178/energy-banner_jr0z7b.png'
export const EnergyBannerMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096167/energy-banner-mobile_qh6ljq.png'
export const HowTo1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096308/howto1_pvbfci.png'
export const HowTo2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096530/howto2_s4axmk.png'
export const HowTo3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096536/howto3_a5yjuk.png'
export const Excellent = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096524/excellent_zfqwmr.png'
export const ProvenQuality = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096367/provenquality_zp1bti.png'
export const ProvenQuality2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096340/provenquality2_qmpu0w.png'
export const Mileage = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096141/mileage_wlhx6k.png'
export const Riders = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096146/riders_tpb8gc.png'
export const TwoWheeler = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096512/twowheeler_wqd17b.png'
export const BSS = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096165/BSS_vfmlhy.png'
export const BSS2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096158/BSS2_dvxrf8.png'
export const BSSLocation = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096160/BSSLocation_ay00mb.png'
export const BSSLocation2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096163/BSSLocation2_zxocfx.png'
export const BSSLocationMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096162/BSSLocation-mobile_hzksqp.png'
export const SaveCost = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096485/save-cost_nouqzf.png'
export const SmartTech = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096420/smart-tech_r2jnza.png'
export const GetNowPlaceholder = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096173/getNowPlaceholder_sbkeca.png'
export const GetNowPlaceholderMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096173/getNowPlaceholderMobile_x2m87g.png'
export const MobileKV = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096475/mobile-kv_t9bs0u.png'
export const AboutUs = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096157/aboutus_grmfk4.png'
export const AboutUsKV = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096156/aboutus-kv_ehaumb.png'
export const AboutUsKVMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096146/aboutus-kv-mobile_pjpdg9.png'
export const AboutUsKV2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730193158/aboutus-kv2_kjj4tc.png'
export const AboutUsKV2Mobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096156/aboutus-kv2-mobile_evvkd0.png'
export const AboutUsSpirit = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096152/aboutus-spirit_xwjcmc.png'
export const AboutUsSpirit2 = 'https://res.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730884008/aboutus-spirit-2_fxe1yd.png'
export const AboutUsSpiritMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096150/aboutus-spirit-mobile_iollbt.png'
export const AboutUsSpiritMobile2 = 'https://res.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730885516/aboutus-spirit-mobile-2_ixxnf6.png'
export const CoinLeft = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096432/coinLeft_rnsjrk.png'
export const CoinRight = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096430/coinRight_ojmifm.png'
export const RectanglePlaceholder = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096532/rectangle-placeholder_lno0np.png'
export const EfficientPlaceholder = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096462/efficient-placeholder_uf26mr.png'
export const TechDesign1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096414/techdesign1_stvau0.png'
export const TechDesign2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096428/techdesign2_rwcr47.png'
export const TechDesign3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096500/techdesign3_qqh2au.png'
export const AboutTD1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096162/abouttd1_jvumn6.png'
export const AboutTD2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096148/abouttd2_zeme0s.png'
export const AboutTD3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096152/abouttd3_idtxxm.png'
export const AboutTD4 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096154/abouttd4_b4nkm4.png'
export const ColorDerawanBlue = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096371/color_derawanblue_b2g7rn.png'
export const ColorIvoryWhite = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096139/color_ivorywhite_kzi8mv.png'
export const ColorRedCherry = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096139/color_redcherry_oyvzeq.png'
export const ColorAmber = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096138/color_amber_yjelja.png'
export const ColorIceNavy = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096139/color_icenavy_acg0iz.png'
export const ColorViridianGreen = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096140/color_viridiangreen_hwru9a.png'
export const ColorBeige = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096139/color_beige_ythvhc.png'
export const ColorGrey = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096139/color_grey_foua0b.png'
export const MickeySquare = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096476/mickey-square_klhokx.png'
export const MickeyPortrait = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096473/mickey-portrait_lflavd.png'
export const ElectrumPulsa1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096164/electrum-pulsa1_ztgody.png'
export const ElectrumPulsa2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096140/electrum-pulsa2_xepn8j.png'
export const Future1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096166/future1_bo41hf.png'
export const Future2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096167/future2_bltsqz.png'
export const Future3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730704139/future3_gxxseg.png'
export const Future4 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096173/future4_uponlq.png'
export const Future5 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096170/future5_acqbqb.png'
export const SepekanKV = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096375/sepekan_snd7d1.png'
export const SepekanKVMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096359/sepekan-mobile_eeptrb.png'
export const SepekanKVMobile2 = ''
export const SepekanKVBanner = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096142/sepekan-banner_rwiszz.jpg'
export const PackageBG = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096263/package-bg_qrthvt.png'
// export const PBB = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096265/pbb_ooxv5w.png'
// export const PSB = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096343/psb_ntokcy.png'
export const PBB = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730431873/pbb-limited_gz7pup.png'
export const PSB = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730431873/psb-limited_hb8eqg.png'
export const Swaps = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096386/swaps_aywkvl.png'
export const BatteryCharge = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096157/battery-charge_cqboed.png'
export const BatterySwap = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096158/battery-swap_po1kuc.png'
export const BatteryChargeDark = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096138/battery-charge-dark_vezzrg.png'
export const BatterySwapDark = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096137/battery-swap-dark_anwmdk.png'
export const IntroProduct = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096214/intro-product_ciu34e.png'
export const IntroProductMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096179/intro-product-mobile_qyhzpa.png'
export const Understand1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096424/understand1_swjbjs.png'
export const Understand2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096432/understand2_logu18.png'
export const Understand3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096428/understand3_vte8pt.png'
export const GradientDark = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096258/gradient-dark_wdq9dp.png'
export const IntroH3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096178/intro-h3_avvh7u.png'
export const IntroH3New = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096177/intro-h3-new_lierhp.png'
export const IntroH3NewV2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730195360/intro-h3-new-v2_lfje8j.png'
export const HomeKV = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096176/home-kv_vefy8x.png'
export const HomeKV2 = 'https://res.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730883484/home-kv-2_rybl5u.png'
export const HomeKVMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096175/home-kv-mobile_taeap0.png'
export const HomeKVMobile2 = 'https://res.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730887609/home-kv-mobile-2_glheo2.png'
export const ProductKV = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096500/product-kv_eckzj9.png'
export const ProductKVMobile = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096138/product-kv-mobile_mm4xaq.png'
export const Mission1 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096286/mission1_zo1cwv.png'
export const Mission2 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096286/mission2_ns4bdy.png'
export const Mission3 = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096320/mission3_lx3brc.png'
export const ElectrumYear = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096164/electrum-year_ykbgsf.png'
export const Showroom = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096530/showroom_tonlzj.png'
export const GetYoursGIF = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096175/get-yours-kv_reptmk.gif'
export const GetYoursMobileGIF = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096170/get-yours-kv-mobile_oox4by.gif'
export const AEON = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096155/aeon_yfk4dl.png'
export const ADIRA = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096153/adira_wwd4aq.png'
export const CIMB = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096160/cimb_ddyn9d.png'
export const BRI = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096157/bri_masivw.png'
export const TRANSPACIFIC = 'https://res-2.cloudinary.com/aldrichelectrum/image/upload/f_auto,q_auto/v1730096415/transpacific_bscf0o.png'
import React, { useRef, useState } from 'react'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import Slider from 'components/slider'

import {
  C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, C14, C15, C16, C17, C18, C19, C20, C21, C22, C23, C24, C25, C26, C27, C28
} from 'data/cost-values'
import { Feature } from 'assets/images'

Chart.register(CategoryScale)

const CostCalculator = () => {
  const [selectedVehicle, setSelectedVehicle] = useState('110')
  const handleVehicleChange = (event) => {
    setSelectedVehicle(event.target.value)
  }

  const [selectedFuel, setSelectedFuel] = useState('Pertamax')
  const handleFuelChange = (event) => {
    setSelectedFuel(event.target.value)
  }

  const [selectedEnergy, setSelectedEnergy] = useState('Charging')
  const handleEnergyChange = (event) => {
    setSelectedEnergy(event.target.value)
  }

  const [selectedBattSubs, setSelectedBattSubs] = useState('Buy')
  const handleBattSubsChange = (event) => {
    setSelectedBattSubs(event.target.value)
  }

  const [distance, setDistance] = useState(40)

  // BATTERY
  const D51 = (distance * 30 / C9 * C17) + C18
  const E51 = (distance * 30 / C9 * C12) + C18
  const F51 = (distance * 30 / C9 * (C16 + C12))
  const G51 = (distance * 30 / C9 * (C16 + C17))

  // H3
  const H3FixedCost = selectedBattSubs === "Buy" ? (C5 / C28) : (C6 / C28)
  const H3EnergyCost = (selectedEnergy === "Charging" && selectedBattSubs === "Buy") ? F51 :
    (selectedEnergy === "Charging" && selectedBattSubs === "Rent") ? E51 :
    (selectedEnergy === "Swapping" && selectedBattSubs === "Buy") ? G51 : D51
  const H3MaintenanceCost = distance > 80 ? C21 : (distance > 40 ? C24 : C27)
  const H3TotalCost = H3FixedCost + H3EnergyCost + H3MaintenanceCost

  // 110 CC
  const ICE110FixedCost = C3 / C28
  const ICE110EnergyCost = (distance * 30 / C7) * (selectedFuel === "Pertalite" ? C10 : C11)
  const ICE110MaintenanceCost = distance > 80 ? C20 : (distance > 40 ? C23 : C26)
  const ICE110TotalCost = ICE110FixedCost + ICE110EnergyCost + ICE110MaintenanceCost

  // 125 CC
  const ICE125FixedCost = C4 / C28
  const ICE125EnergyCost = (distance * 30 / C8) * (selectedFuel === "Pertalite" ? C10 : C11)
  const ICE125MaintenanceCost = distance > 80 ? C19 : (distance > 40 ? C22 : C25)
  const ICE125TotalCost = ICE125FixedCost + ICE125EnergyCost + ICE125MaintenanceCost

  const H3VSICE110 = ICE110TotalCost - H3TotalCost
  const H3VSICE125 = ICE125TotalCost - H3TotalCost
  const CostSavings = selectedVehicle === '110' ? H3VSICE110 : H3VSICE125

  const chartRef = useRef(null)

  const getGradient = (context, color1, color2) => {
    const gradient = context.createLinearGradient(0, 0, 0, context.canvas.clientHeight || 0)
    gradient.addColorStop(0, color1)
    // gradient.addColorStop(1, color2)
    gradient.addColorStop(0.5, color2)
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')
    return gradient
  }

  const renderCostCalculator = () => {
    return (
      <React.Fragment>
        {/* SLIDER */}
        <center style={{ marginBottom: '2rem', padding: '1rem', backgroundColor: '#F4F7FA', borderRadius: '0.65rem' }}>
          <Slider
            label="Jarak Tempuh per Hari"
            minValue={10}
            maxValue={100}
            increment={5}
            initialValue={40}
            onValueChange={(e) => setDistance(e)}
          />
        </center>
        {/* CHART */}
        <div>
          <Bar
            ref={chartRef}
            datasetIdKey='id'
            height='300px'
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                  grid: { display: false },
                },
                y: {
                  stacked: true,
                  // max: 1400000,
                  ticks: {
                    stepSize: 200000,
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    padding: 15,
                  },
                },
              },
            }}
            data={{
              labels: [
                'Electrum H3',
                `ICE - ${selectedVehicle} CC`,
              ],
              datasets: [
                {
                  id: 1,
                  label: 'Fixed Cost',
                  data: [
                    H3FixedCost.toFixed(0),
                    (selectedVehicle === '110' ? ICE110FixedCost.toFixed(0) : ICE125FixedCost.toFixed(0))
                  ],
                  backgroundColor: ['#103856'],
                  barThickness: 55,
                },
                {
                  id: 2,
                  label: 'Maintenance Cost',
                  data: [
                    H3MaintenanceCost.toFixed(0),
                    (selectedVehicle === '110' ? ICE110MaintenanceCost.toFixed(0) : ICE125MaintenanceCost.toFixed(0))
                  ],
                  backgroundColor: ['#F04C3E'],
                  barThickness: 55,
                },
                {
                  id: 3,
                  label: 'Energy Cost',
                  data: [
                    H3EnergyCost.toFixed(0),
                    (selectedVehicle === '110' ? ICE110EnergyCost.toFixed(0) : ICE125EnergyCost.toFixed(0))
                  ],
                  backgroundColor: ['#1ECECA'],
                  barThickness: 55,
                },
                CostSavings > 0 && {
                  id: 4,
                  label: 'Saved Cost',
                  data: [ CostSavings.toFixed(0) ],
                  backgroundColor: ['#B5FF92'],
                  barThickness: 55,
                },
              ],
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <div style={{ padding: '3.5rem 12.5rem', backgroundColor: 'white', borderRadius: '1.125rem' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '3.5rem' }}>
        {/* LEFT SECTION */}
        <div>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.5rem', marginBottom: '1rem' }}>
            Savings Calculator
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '2rem' }}>
            This section will show the user’s saving estimation when they use electrum H3
          </div>
          {/* DROPDOWNS */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem', marginBottom: '1.25rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Electrum
              </div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#848484' }}>
                VS
              </div>
              <select
                value={selectedVehicle}
                onChange={handleVehicleChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="110">ICE - 110CC</option>
                <option value="125">ICE - 125CC</option>
              </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Energy
              </div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#848484' }}>
                VS
              </div>
              <select
                value={selectedFuel}
                onChange={handleFuelChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="Pertamax">Pertamax</option>
                <option value="Pertalite">Pertalite</option>
              </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Energy Method :
              </div>
              <select
                value={selectedEnergy}
                onChange={handleEnergyChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="Charging">Charging</option>
                <option value="Swapping">Swapping</option>
              </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Battery Subs :
              </div>
              <select
                value={selectedBattSubs}
                onChange={handleBattSubsChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="Buy">Beli Baterai</option>
                <option value="Rent">Sewa Baterai</option>
              </select>
            </div>
          </div>
          {/* SAVED */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: '0.5rem',
            border: '1.5px solid #1ECECA',
            borderRadius: '0.5rem',
            padding: '0.75rem 0',
            marginTop: '2rem',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#103856' }}>
              Kamu menghemat sebesar
            </div>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1rem', color: '#1ECECA' }}>
              Rp. {CostSavings > 0 ? CostSavings.toLocaleString('id-ID', { maximumFractionDigits: 0 }) : 0}
              <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#103856' }}>/bulan</span>
            </div>
            <div style={{ display: (CostSavings < 0 ? 'none' : 'block') }}>👍</div>
          </div>
        </div>
        {/* RIGHT SECTION */}
        <div>
          {/* TOP DROPDOWN */}
          {/* <div style={{ display: 'flex', columnGap: '2.5rem', marginBottom: '1.25rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Electrum
              </div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#848484' }}>
                VS
              </div>
              <select
                value={selectedVehicle}
                onChange={handleVehicleChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="110">ICE - 110CC</option>
                <option value="125">ICE - 125CC</option>
              </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Energy
              </div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#848484' }}>
                VS
              </div>
              <select
                value={selectedFuel}
                onChange={handleFuelChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="Pertamax">Pertamax</option>
                <option value="Pertalite">Pertalite</option>
              </select>
            </div>
          </div> */}
          {/* BOTTOM DROPDOWN */}
          {/* <div style={{ display: 'flex', columnGap: '2.5rem', marginBottom: '1.25rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Energy Method :
              </div>
              <select
                value={selectedEnergy}
                onChange={handleEnergyChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="Charging">Charging</option>
                <option value="Swapping">Swapping</option>
              </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.85rem', color: '#0B4571' }}>
                Battery Subs :
              </div>
              <select
                value={selectedBattSubs}
                onChange={handleBattSubsChange}
                style={{
                  border: 'none',
                  backgroundColor: '#D8E9E8',
                  padding: '0.35rem 0.25rem',
                  borderRadius: '0.25rem',
                  color: '#0B4571',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.85rem',
                }}
              >
                <option value="Buy">Beli Baterai</option>
                <option value="Rent">Sewa Baterai</option>
              </select>
            </div>
          </div> */}
          {/* COMPARISON CHART */}
          {renderCostCalculator()}
        </div>
      </div>
    </div>
  )
}

export default CostCalculator

import React, { useContext, useState } from 'react'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'
import { ElectrumBlue } from 'assets/icons'
import { FormattedMessage } from 'react-intl'

const defaults = require('languages/id.json')

const Product = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  // ROUTES
  const routesArr = [
    {
      name: 'header_home',
      route: '/',
    },
    {
      name: 'header_product',
      route: '/products',
    },
    {
      name: 'header_energy',
      route: '/energy',
    },
    {
      name: 'header_getYours',
      route: '/get-yours',
    },
    {
      name: 'header_aboutUs',
      route: '/about-us',
    },
    {
      name: 'header_news',
      route: '/news',
    },
  ]
  const renderRouteNavigations = () => {
    return routesArr.map((item, index) => (
      <div
        key={index}
        style={{
          cursor: 'pointer',
          fontFamily: 'Gilroy-Bold',
          fontSize: '1.125rem',
          color: '#103856',
          width: 'fit-content',
          borderBottom: pathname === item.route ? '0.175rem solid #1ECECA' : 'none',
        }}
        onClick={() => navigate(item.route)}
      >
        <FormattedMessage
          id={item.name}
          defaultMessage={defaults[item.name]}
        />
      </div>
    ))
  }

  return (
    <div style={{
      position: 'sticky',
      top: '0',
      zIndex: 999,
    }}>

      {/* HEAD */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.75rem 2rem',
        backgroundColor: 'white',
      }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={showDropdown ? faTimes : faBars}
            color='#103856'
            fontSize='1.5rem'
            className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
            onAnimationEnd={handleAnimationEnd}
          />
        </div>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <ElectrumBlue />
        </div>
        <div style={{ visibility: 'hidden' }}>
          <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
        </div>
      </div>

      {/* CONTENT */}
      <div style={{ display: showDropdown ? 'block' : 'none' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '1rem',
          backgroundColor: 'white',
          textAlign: 'center',
          padding: '0.5rem 5rem 1rem 5rem',
          borderRadius: '0 0 1rem 1rem',
        }}>
          {renderRouteNavigations()}
        </div>
      </div>

    </div>
  )
}

export default Product

import React from "react";

const ArrowLeft = ({ width = "19", height = "19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 19 19"
    >
      <rect
        width="2.133"
        height="12.8"
        fill="currentColor"
        rx="1.067"
        transform="matrix(.7071 -.7071 -.70711 -.7071 9.05 18.55)"
      ></rect>
      <rect
        width="2.133"
        height="12.8"
        fill="currentColor"
        rx="1.067"
        transform="matrix(-.70711 -.7071 -.7071 .7071 10.559 1.957)"
      ></rect>
    </svg>
  );
}

export default ArrowLeft;

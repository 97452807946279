import React from "react"

const Whatsapp = ({ width="120", height="120" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 120 120"
    >
      <g filter="url(#filter0_d_2758_4184)">
        <circle cx="60" cy="58" r="50" fill="#40C0C0"></circle>
      </g>
      <g clipPath="url(#clip0_2758_4184)">
        <path
          fill="#fff"
          d="M73.68 63.955c-.743-.372-4.396-2.167-5.076-2.417-.682-.248-1.177-.37-1.675.375-.492.742-1.917 2.415-2.35 2.91-.432.497-.867.557-1.61.187-.742-.375-3.137-1.157-5.975-3.687-2.207-1.97-3.7-4.403-4.132-5.148-.433-.742-.045-1.145.325-1.515.335-.332.745-.867 1.115-1.3.372-.435.495-.745.745-1.242.247-.495.125-.928-.063-1.3-.187-.373-1.672-4.03-2.29-5.518-.605-1.447-1.217-1.25-1.672-1.275A32.09 32.09 0 0049.597 44c-.495 0-1.3.185-1.98.93-.68.743-2.6 2.54-2.6 6.198 0 3.655 2.662 7.187 3.032 7.685.373.495 5.24 8 12.693 11.217a41.994 41.994 0 004.235 1.563c1.78.567 3.4.487 4.677.295 1.428-.213 4.395-1.798 5.015-3.533.62-1.735.62-3.222.433-3.532-.185-.31-.678-.495-1.423-.868zM60.123 82.463h-.01c-4.425 0-8.77-1.19-12.577-3.445l-.903-.535-9.352 2.455 2.495-9.12-.588-.935a24.65 24.65 0 01-3.775-13.15c.003-13.625 11.09-24.71 24.72-24.71 6.6 0 12.805 2.575 17.47 7.245a24.561 24.561 0 017.233 17.485c-.008 13.625-11.093 24.71-24.713 24.71zM81.157 36.72A29.536 29.536 0 0060.124 28C43.737 28 30.4 41.338 30.392 57.73c0 5.24 1.367 10.355 3.97 14.863L30.142 88l15.762-4.135a29.705 29.705 0 0014.208 3.62h.012c16.385 0 29.725-13.337 29.733-29.732a29.553 29.553 0 00-8.7-21.033z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_2758_4184"
          width="120"
          height="120"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2758_4184"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2758_4184"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_2758_4184">
          <path
            fill="#fff"
            d="M0 0H60V60H0z"
            transform="translate(30 28)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Whatsapp
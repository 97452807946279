import React, { useContext, useState } from 'react'
import { FooterMobile, HeaderMobile } from 'components'
import { Context } from 'helpers/language-provider'

import { H3, H5 } from 'assets/images'
import ProductH3 from './h3'
import ProductH5 from './h5'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { ElectrumBlue } from 'assets/icons'
import { FormattedMessage } from 'react-intl'

const defaults = require('languages/id.json')

const Product = () => {
  const navigate = useNavigate()

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [selectedProduct, setSelectedProduct] = useState('H3')

  return (
    <div style={{ backgroundColor: '#EBF0F5' }}>

      <HeaderMobile />

      {/* PRODUCT TAB */}
      {/* <div style={{
        backgroundColor: '#F4F9FA',
        display: 'flex',
        justifyContent: 'center',
        columnGap: '4.25rem',
        paddingTop: '1rem',
      }}>
        <div
          onClick={() => setSelectedProduct('H3')}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy'  src={H3} alt='H3' height={90} />
          <div
            style={{
              fontFamily: selectedProduct === 'H3' ? 'Gilroy-Bold' : 'Gilroy-SemiBold',
              color: selectedProduct === 'H3' ? '#40C0C0' : '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom: selectedProduct === 'H3' ? '0.45rem solid #1ECECA' : 'none',
            }}
          >
            ELECTRUM H3
          </div>
        </div>
        <div
          onClick={() => setSelectedProduct('H5')}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy'  src={H5} alt='H5' height={90} />
          <div
            style={{
              fontFamily: selectedProduct === 'H5' ? 'Gilroy-Bold' : 'Gilroy-SemiBold',
              color: selectedProduct === 'H5' ? '#40C0C0' : '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom: selectedProduct === 'H5' ? '0.45rem solid #1ECECA' : 'none',
            }}
          >
            ELECTRUM H5
          </div>
        </div>
      </div> */}

      {
        selectedProduct === 'H3'
        ? <ProductH3 />
        : <ProductH5 />
      }

      <FooterMobile />
    </div>
  )
}

export default Product
